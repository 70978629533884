import { MinApp } from '../components/MinApp';
import { PageContext }from '../contexts/pageContext'
import Head from 'next/head';
import Script from 'next/script';
import { getConfig } from '../contexts/configs/index'
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { AuthShell } from '../components/shell/AuthShell';
import { supabase_public } from '../lib/supabaseClient';
import {merchantMonetizeAndSwap, completeCheckout, startCheckoutWithLink} from "../components/utils/IonicCommerce"

const HELP_API = `${process.env.NEXT_PUBLIC_API_URL}extension/product/help`
const REVIEWS_API = `${process.env.NEXT_PUBLIC_API_URL}extension/product/review`;
const CHEAPER_API = `${process.env.NEXT_PUBLIC_API_URL}extension/product/cheaper`;


export default function CatchAllPage() {
  const router = useRouter();
  const { slug } = router.query;
  const [productInfo, setProductInfo] = useState(null);
  const [reviews, setReviews] = useState(null);
  const [cheaperOptions, setCheaperOptions] = useState(null);
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [isLoadingReviews, setIsLoadingReviews] = useState(false);
  const [isLoadingDeals, setIsLoadingDeals] = useState(false);
  const [loggedInUsername, setLoggedInUsername] = useState("");
  const [uid, setUser] = useState(null);
  const [sessionUUID, setSessionUUID] = useState("");
  const [showAuthPrompt, setShowAuthPrompt] = useState(false);
  const [feedbackOpened, setFeedbackOpened] = useState(false);
  const [profile, setProfile] = useState(null);



  useEffect(() => {
    async function fetchProductInfo() {
      if (slug) {
        const url = Array.isArray(slug) ? slug.join('/') : slug;
        const urlParams = new URLSearchParams(window.location.search);
        const title = urlParams.get('title');
        const price = urlParams.get('price');
        const imageUrl = urlParams.get('imageUrl');

        let productData;
        setIsLoadingProduct(true);

        if (title && price && imageUrl) {
          // Use query params if available
          productData = {
            title,
            price,
            imageUrl: imageUrl,
            url
          };
          setProductInfo(productData);
          setIsLoadingProduct(false);

        } else {
          // Fallback to API call if params not available
          try {
            const response = await fetch(HELP_API, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                url
              }),
            });
            productData = await response.json();
            setProductInfo(productData);
            setIsLoadingProduct(false);
          } catch (error) {
            console.error('Error fetching product info:', error);
            setIsLoadingProduct(false);
            return;
          }
        }

        // Get reviews and cheaper options once we have product info
        if (productData && productData.title) {
          const modifiedProduct = { ...productData, detailPageURL: productData.url };
          setIsLoadingReviews(true);
          setIsLoadingDeals(true);
          // Fetch reviews and cheaper options in parallel
          Promise.all([
            // Get affiliate link
            merchantMonetizeAndSwap(modifiedProduct, '', modifiedProduct.title)
              .then(result => {
                setProductInfo(prev => ({...prev, taggedLink: result.taggedURL}));
              }),

            // Fetch reviews
            fetch(REVIEWS_API, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ product: modifiedProduct }),
            })
            .then(response => {
              if (response.ok) {
                return response.json();
              }
              throw new Error('Reviews fetch failed');
            })
            .then(data => {
              setReviews(data);
              setIsLoadingReviews(false);
            })
            .catch(error => {
              console.error('Error fetching reviews:', error);
              setIsLoadingReviews(false);
            }),

            // Fetch cheaper options
            fetch(CHEAPER_API, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ product: modifiedProduct }),
            })
            .then(response => {
              if (response.ok) {
                return response.json();
              }
              throw new Error('Cheaper options fetch failed');
            })
            .then(data => {
              setCheaperOptions(data);
              setIsLoadingDeals(false);
            })
            .catch(error => {
              console.error('Error fetching cheaper options:', error);
              setIsLoadingDeals(false);
            })
          ]);
        }
      }
    }
    const url = Array.isArray(slug) ? slug.join('/') : slug;
    if (!productInfo || productInfo.url !== url) {
      console.log("fetching fresh data")
      fetchProductInfo();
    }
  }, [slug]);



  async function getProfile(uid) {
      try {
          const response = await fetch(process.env.NEXT_PUBLIC_API_URL + '/getUserProfile', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({ uid: uid })
          });

          const result = await response.json();

          // Handle the result here
          if (result.username) {
              setLoggedInUsername(result.username); // Assuming setLoggedInUsername is not async
              setProfile(result); // Assuming setProfile is not async
          }
      } catch (error) {
          // Handle the error here
          console.log(error);
      }
  }


  useEffect(() => {
      async function fetchAllData() {
          const { data, error } = await supabase_public.auth.getSession();
          if (error) {
              console.log("error retrieving session");
              return;
          }
          // Set the session data
          // Assuming the structure of data is { session: { user: { email } } }
          const email = data?.session?.user?.email;
          const id = data?.session?.user?.id;
          // console.log("User logged in with id", id)
          if (id && id !== uid) {
              await getProfile(id);
          }

      }
      fetchAllData();

  }, []); // Empty dependency array ensures this runs once on mount

  useEffect(() => {
      async function Profile() {
          if (uid) {
              await getProfile(uid);
          }
      }
      Profile();
  }, [uid]); // Empty dependency array ensures this runs once on mount


  supabase_public.auth.onAuthStateChange( async (event, session) => {
      // const hostname = window.location.hostname;
      // const domain = `domain=${hostname};`;

      if (event === 'SIGNED_OUT' ) {
        // delete cookies on sign out
        const expires = new Date(0).toUTCString()
        document.cookie = `my-access-token=; path=/; expires=${expires}; SameSite=Lax; secure`
        document.cookie = `my-refresh-token=; path=/; expires=${expires}; SameSite=Lax; secure`


      } else if (event === 'SIGNED_IN') {
        const maxAge =  24 * 60 * 60; //force redeloy
        document.cookie = `my-access-token=${session.access_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`
        document.cookie = `my-refresh-token=${session.refresh_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`
      //   if someone is user is not logged in but has a session
        if (! uid && session ) {
          const id = session?.user?.id
          if (id) {
              setUser(id)
          }
        }
      } else if ( event === 'TOKEN_REFRESHED') {
          const maxAge =  24 * 60 * 60; //force redeloy
          document.cookie = `my-access-token=${session.access_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`
          document.cookie = `my-refresh-token=${session.refresh_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`
        //   if someone is user is not logged in but has a session
      }
  })

  return (
    <>
      <Head>
        <title>{productInfo?.title || 'Claros'}</title>
        <link rel="icon" href="/logo.png" />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Head>
      <PageContext.Provider value={getConfig("minapp")}>
        <AuthShell
          loggedInUsername={loggedInUsername}
          userid={uid}
          sessionUUID={sessionUUID}
          showAuthPrompt={showAuthPrompt}
          setShowAuthPrompt={setShowAuthPrompt}
          topicSelected={true}
          feedbackOpened={feedbackOpened}
          setFeedbackOpened={setFeedbackOpened}
          profile={profile}
        >
          {(isLoadingProduct || productInfo) ? (
            <div className="min-h-screen bg-bgwhite py-12 px-4 sm:px-6 lg:px-8">
              <div className="flex gap-8">
                <div className="w-1/2">
                  <ProductSection productInfo={productInfo} reviews={reviews} isLoading={isLoadingProduct}/>
                  <ReviewsSection reviews={reviews} isLoading={isLoadingReviews} />
                </div>
                <DealsSection cheaperOptions={cheaperOptions} isLoading={isLoadingDeals} />
              </div>
            </div>
          ) : (
            <div className="min-h-screen flex items-center justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-500"></div>
            </div>
          )}
        </AuthShell>
      </PageContext.Provider>
    </>
  )
}

const ProductSection = ({ productInfo, reviews, isLoading }) => {
  const [loadingMessage, setLoadingMessage] = useState("Loading page");
  const [averageScore, setAverageScore] = useState(null);

  useEffect(() => {
    if (isLoading) {
      const messages = [
        "Loading page",
        "Extracting Content",
        "Finding Deals",
        "Looking for Reviews",
        "This ones tough"
      ];

      let currentIndex = 0;
      const interval = setInterval(() => {
        currentIndex = (currentIndex + 1) % messages.length;
        setLoadingMessage(messages[currentIndex]);
      }, 3500);

      return () => clearInterval(interval);
    }
  }, [isLoading]);

  const calculateAverageScore = () => {
    if (!reviews || reviews.length === 0) return null;

    const sources = new Set();
    let totalScore = 0;
    let reviewCount = 0;

    reviews.forEach(review => {
      if (review.links && review.links[0]) {
        const hostname = new URL(review.links[0]).hostname;
        sources.add(hostname);
      }
      if (review.product_score) {
        totalScore += parseFloat(review.product_score);
        reviewCount++;
      }
    });

    if (reviewCount === 0) return null;

    return {
      score: (totalScore / reviewCount).toFixed(1),
      sources: Array.from(sources)
    };
  };

  if (!isLoading && !productInfo) return null;

  useEffect(() => {
      setAverageScore(calculateAverageScore());
  }, [reviews]);


  return (
    <div className={`bg-white rounded-2xl shadow-lg overflow-hidden transform transition-all hover:shadow-xl ${isLoading ? 'animate-pulse' : ''}`}>
      <div className="flex">
        {(isLoading || productInfo?.imageUrl) && (
          <div className="h-[300px] w-1/2 relative bg-gray-50">
            {isLoading ? (
              <div className="absolute h-full w-full bg-gray-200 flex items-center justify-center">
                <p className="text-gray-600 font-medium">{loadingMessage}</p>
              </div>
            ) : (
              <div className="h-full">
                <img
                  src={productInfo.imageUrl}
                  alt={productInfo.title}
                  className="absolute h-full w-full object-contain p-4"
                />
                {averageScore && (
                  <>
                    <div className={`absolute top-4 right-4 ${
                      parseFloat(averageScore.score) > 7.5 ? 'bg-green-500' :
                      parseFloat(averageScore.score) >= 5 ? 'bg-yellow-500' :
                      'bg-red-500'
                    } text-white rounded-full h-16 w-16 flex items-center justify-center`}>
                      <span className="text-xl font-bold">{averageScore.score}</span>
                    </div>
                    <div className="absolute bottom-4 left-4 right-4">
                      <div className="bg-white bg-opacity-90 p-2 rounded-lg shadow">
                        <p className="text-sm font-medium text-gray-600">Review Sources:</p>
                        <div className="flex flex-wrap gap-1 mt-1">
                          {averageScore.sources.map((source, index) => (
                            <span key={index} className="text-xs bg-gray-200 rounded px-2 py-1">
                              {source}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        )}
        <div className="p-6 w-1/2">
          <div className="inline-block px-3 py-1 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800">
            Product Details
          </div>
          {isLoading ? (
            <>
              <div className="mt-3 h-8 bg-gray-200 rounded-lg w-3/4"></div>
              <div className="mt-3 h-6 bg-gray-200 rounded-lg w-1/4"></div>
            </>
          ) : (
            <>
              <h1 className="mt-3 text-2xl font-bold text-gray-900 leading-tight tracking-tight">
                {productInfo.title}
              </h1>
              {productInfo.price && (
                <p className="mt-3 text-2xl font-bold text-indigo-600">
                  {productInfo.price}
                </p>
              )}

            </>
          )}
          <div className="mt-4">
            {!isLoading && (
              <a
                href={productInfo.taggedLink || productInfo.url}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center justify-center w-full px-4 py-2 border border-transparent text-sm font-medium rounded-lg text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200 shadow-md hover:shadow-lg"
              >
                View More Details
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const DealsSection = ({ cheaperOptions, isLoading }) => {
  if (!isLoading && !cheaperOptions?.length) return null;

  return (
    <div className={`w-1/2 p-6 ${isLoading ? 'animate-pulse' : ''}`}>
      <div className="inline-block px-3 py-1 rounded-full text-sm font-medium bg-purple-100 text-purple-800 mb-6">
          Better Buying Options
        </div>
      {isLoading ? (
        [...Array(3)].map((_, index) => (
          <div key={index} className="mb-4 border rounded-lg p-4 flex items-start space-x-4">
            <div className="w-24 h-24 bg-gray-200 rounded-md"></div>
            <div className="flex-1">
              <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
              <div className="h-6 bg-gray-200 rounded w-1/4 mb-2"></div>
              <div className="h-8 bg-gray-200 rounded w-1/3"></div>
            </div>
          </div>
        ))
      ) : (
        cheaperOptions.map((option, index) => (
          <div key={index} className="mb-4 border border-gray-300 rounded-lg p-4 flex items-start space-x-4 bg-white">
            <img src={option.imageUrl} alt={option.title} className="w-24 h-24 object-cover rounded-md" />
            <div className="flex-1">
              <a href={option.taggedLink || option.detailPageURL} target="_blank" rel="noopener noreferrer" className="text-sm text-blue-600 hover:underline line-clamp-2">
                {option.title}
              </a>
              <div className="mt-2 text-xl font-bold">{option.price}</div>
              {option.rating && (
                <div className="flex items-center mt-1">
                  <span className="text-amber-500">{option.rating} {'★'.repeat(Math.round(option.rating))}</span>
                  <span className="text-gray-500 text-sm ml-1">({option.reviews_count})</span>
                </div>
              )}
              <a
                href={option.taggedLink || option.detailPageURL}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-2 inline-block bg-purple-600 text-white px-4 py-2 rounded-md hover:bg-purple-700 transition duration-200"
              >
                View Deal
              </a>
            </div>
            <img
              src={`https://www.google.com/s2/favicons?domain=${option.detailPageURL}&sz=64`}
              alt="favicon"
              className="w-4 h-4 rounded-full"
            />
          </div>
        ))
      )}
    </div>
  );
};

const ReviewsSection = ({ reviews, isLoading }) => {
  const [groupedReviews, setGroupedReviews] = useState<{[key: string]: any[]}>({});

  useEffect(() => {
    if (reviews) {
      // Group reviews by source domain
      const grouped = reviews.reduce((acc, review) => {
        if (review.links && review.links[0]) {
          const domain = new URL(review.links[0]).hostname;
          if (!acc[domain]) {
            acc[domain] = [];
          }
          acc[domain].push(review);
        }
        return acc;
      }, {} as {[key: string]: any[]});
      setGroupedReviews(grouped);
    }
  }, [reviews]);

  if (!isLoading && !reviews?.length) return null;
  if (!isLoading && !reviews?.length) {
    return (
      <div className="mt-4 bg-white rounded-2xl shadow-lg p-8 text-center">
        <div className="inline-block my-3 px-3 py-1 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800 mb-6">
          Product Reviews
        </div>
        <p className="text-gray-600">I couldn't find any reviews for you for that specific product. Sorry, about that!</p>
      </div>
    );
  }

  return (
    <div className={`mt-4 bg-white rounded-2xl shadow-lg p-8 ${isLoading ? 'animate-pulse' : ''}`}>
      <div className="inline-block my-3 px-3 py-1 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800 mb-6">
        Product Reviews
      </div>
      {isLoading ? (
        [...Array(2)].map((_, index) => (
          <div key={index} className="mb-6 border-b pb-4 last:border-b-0">
            <div className="flex items-center space-x-2">
              <div className="w-4 h-4 bg-gray-200 rounded-full"></div>
              <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            </div>
            <div className="mt-2">
              <div className="h-2 bg-gray-200 rounded w-40 mb-2"></div>
              <div className="h-4 bg-gray-200 rounded w-3/4"></div>
            </div>
          </div>
        ))
      ) : (
        Object.entries(groupedReviews).map(([domain, reviews]) => (
          <div key={domain} className="mb-8">
            <div className="flex items-center space-x-2 mb-4">
              <img
                src={`https://www.google.com/s2/favicons?domain=${domain}&sz=64`}
                alt="favicon"
                className="w-6 h-6"
              />
              <span className="font-bold text-lg">{domain}</span>
            </div>

            {reviews.map((review, index) => (
              <div key={index} className="mb-6 border-b pb-4 last:border-b-0">
                <div className="flex items-center justify-between">
                  <h3 className="font-semibold text-lg mb-2">
                    {review.title}
                    <a href={review.links[0]} target="_blank" rel="noopener noreferrer" className="text-indigo-600 ml-2 inline-flex">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 24 24" fill="none"
                           stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6" />
                        <path d="M11 13l9 -9" />
                        <path d="M15 4h5v5" />
                      </svg>
                    </a>
                  </h3>
                </div>

                {review.product_score && (
                  <div className="flex items-center space-x-2 mt-2">
                    <div className="w-40 h-2 bg-gray-200 rounded-full overflow-hidden">
                      <div className={`h-full rounded-full ${
                        review.product_score <= 5 ? 'bg-red-500' :
                        review.product_score <= 7 ? 'bg-amber-500' :
                        'bg-green-500'
                      }`} style={{width: `${review.product_score * 10}%`}}></div>
                    </div>
                    <span className="text-sm font-semibold text-gray-600">{review.product_score}/10</span>
                  </div>
                )}

                {review.rating && (
                  <div className="text-amber-500 mt-1">
                    {review.rating} {'★'.repeat(Math.round(review.rating))}
                  </div>
                )}

                <p className="mt-2 text-gray-700">{review.review_summary}</p>
              </div>
            ))}
          </div>
        ))
      )}
    </div>
  );
};
