import React from 'react';
import axios from 'axios';
import posthog from 'posthog-js';
import {CompleteCheckoutRequest} from './IonicCommerceInterfaces';
import { updateProductLinkFallback } from './Affiliates';


const resolveSSPALink = (product) => {
  if (product.detailPageURL.includes('amazon.com/sspa/click')) {
    product.detailPageURL = `https://www.amazon.com/dp/${product.sku}`;
  }
};

export const merchantMonetizeAndSwap = async (product, affiliate_link, searchQuery) => {
  try {
    let { taggedLink, merchants } = await getMonetizedLink(product, searchQuery);
    // console.log({ ...product, taggedURL: taggedLink, merchants: merchants });
    return { ...product, taggedURL: taggedLink, merchants: merchants };
  } catch (error) {
    console.error('Error updating product link:', error);
    console.error('Do Fallback');
    updateProductLinkFallback(product, affiliate_link);
    return product; // Return original product if there's an error
  }
}

export const getMonetizedLink = async (product, searchQuery) => {

  resolveSSPALink(product)
  let tot = product.hasOwnProperty('search_engine');

  const timestamp = new Date().getTime();
  const requestBody = {
  reference_id: `${window.location.href}`,
  user_details: {
      id: "1",
      email: "test@example.com",
      postal_code: "12345"
  },
  client_details: {
      user_agent: "", // Getting the user agent from the browser
      ip: "123.456" // This should be the actual IP address of the client, handled server-side or via a service
  },
  query: {
      q: searchQuery // This should be dynamically set based on the user's actual query if applicable
  },
  product: {
      title: product.title,
      link: product.detailPageURL || product.url,
      image_url: product.imageUrl,
      identifiers: {
          ...(tot ? { sku: product.sku } : { asin: product.sku })
      },
      price: {
          amount: parseFloat(product.price.replace(/[$,]/g, ''))*100 ,
          currency: "USD"
      },
      reference_id: `${window.location.href}`,
  }
};
// console.log(requestBody)
try {
  const response = await axios.post(`/api/affiliates/monetizeLinkIonic`, requestBody, {
  });
  // console.log("Response", response)
  // Parsing the response
  const result = {
    id: response.data.id,
    originalLink: response.data.product.links.original,
    taggedLink: response.data.product.links.tagged,
    merchants: response.data.merchant_links
  };
  return result;
} catch (error) {
  console.error('Error posting product link:', error);
  throw error;
}
};


export const startCheckoutWithLink = async (product) => {

  const url = `/api/affiliates/startCheckoutLink`;
  const data = {
    link: product.detailPageURL,
    success_url:"embedded",
    external_reference_id: `${window.location.href}`, // Optional
    external_metadata: {
      key1: 'value1',
      key2: 'value2'
    } // Optional
  };

  try {
    const response = await axios.post(url, data);
    // console.log("checkout data", response.data);
    if (response.status != 200) {
      posthog.capture("failed checkout",{link: url} )
      return null
    } else {
      return response.data
    }
  } catch (error) {
    posthog.capture("failed checkout", {link: url, "stage": "start"})
    console.error("error with starting checkout", error.response?.data || error.message);
    return null
  }
};


export const completeCheckout = async (checkoutInfo) => {
  const url = `/api/affiliates/completeCheckout`;
  try {
    const requestBody: CompleteCheckoutRequest = {
      checkout_token: checkoutInfo.checkout_token,
      billing_info: checkoutInfo.billing_info,
      shipping_info: checkoutInfo.shipping_info,
      payment_info: checkoutInfo.payment_info
    };
    const response = await axios.post(url, requestBody);
    // console.log(response.data);
    if (response.status != 200) {
      posthog.capture("failed checkout",{link: url, stage:"confirmation"} )
      return null
    } else {
      return response.data
    }
  } catch (error) {
    posthog.capture("failed checkout",{link: url} )
    console.error(error.response?.data || error.message);
    return null
  }
};



